<template>
  <div class="box box_column" style="height: 100%">
    <div class="flex1">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "dataMamagement"
};
</script>

<style scoped></style>
